import axios from "axios";
import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import CountryData from "json/countries.json";

interface Country {
  name: string;
  code: string;
  flag: string;
}

interface Props {
  onSelect: (country: Country) => void;
}

const CountryDropdown: React.FC<Props> = ({ onSelect }) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [search, setSearch] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  useEffect(() => {
    setCountries(CountryData);
  }, []);

  const handleSelect = (country: Country) => {
    setSelectedCountry(country);
    setShowDropdown(false);
    onSelect(country);
  };

  const countriesFiltered = useMemo(() => {
    return countries.filter((c) =>
      c.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [countries, search]);

  return (
    <div className="relative mt-1">
      <div
        className="border p-2 flex items-center cursor-pointer rounded-md"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {selectedCountry ? (
          <>
            <img
              src={selectedCountry.flag}
              alt="flag"
              className="w-6 h-4 mr-2"
            />
            {selectedCountry.name}
          </>
        ) : (
          <p className="text-bash !font-SatoshiRegular text-base w-full justify-between flex items-center">
            <span>Select Country</span>
            <span>
              <IoIosArrowDown className="text-ashShade-2" />
            </span>
          </p>
        )}
      </div>

      {showDropdown && (
        <div className="absolute bg-white border rounded-b-md w-full max-h-60 overflow-y-auto z-10 shadow-md">
          {/* <div> */}

          <div className="m-2 px-2 flex bg-pbg items-center rounded-md sticky top-0 right-0 left-0">
            <input
              type="text"
              placeholder="Search..."
              className="w-full p-2 !border-transparent !border-none outline-none bg-transparent"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <span>
              <IoSearchOutline className="text-2xl" />
            </span>
          </div>

          {countriesFiltered
            // .filter((c) => c.name.toLowerCase().includes(search.toLowerCase()))
            .map((country) => (
              <div
                key={country.code}
                className="p-2 flex items-center hover:bg-bbg cursor-pointer font-SatoshiRegular text-base"
                onClick={() => handleSelect(country)}
              >
                <img
                  src={country.flag}
                  alt="flag"
                  className="w-6 h-4 mr-2 rounded-[4px]"
                />
                {country.name}
              </div>
            ))}
        </div>
        // </div>
      )}
    </div>
  );
};

export default CountryDropdown;

import axios from "axios";
import { useState, useEffect, useMemo } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import CountryData from "json/countries.json";

interface Country {
  name: string;
  code: string;
  flag: string;
  dialCode: string;
}

const defaultCountry: Country = {
  name: "Canada",
  code: "CA",
  flag: "https://flagcdn.com/ca.svg",
  dialCode: "+1",
};

interface PhoneData {
  country: Country | null;
  phoneNumber: string;
}

interface Props {
  onPhoneChange: (data: PhoneData) => void;
}

const PhoneInput: React.FC<Props> = ({ onPhoneChange }) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(
    defaultCountry
  );
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setCountries(CountryData);
  }, []);

  const handleSelectCountry = (country: Country) => {
    setSelectedCountry(country || defaultCountry);
    onPhoneChange({
      country: selectedCountry || defaultCountry,
      phoneNumber: phoneNumber,
    });
    setShowDropdown(false);
  };

  const countriesFiltered = useMemo(() => {
    return countries.filter((c) =>
      c.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [countries, search]);

  return (
    <div className="!relative flex items-center border h-10 rounded-md mt-1 border-ashShade-3">
      <div className="h-10 flex items-center justify-center">
        <button
          className="flex items-center px-2"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div className="text-bash text-base w-full justify-between flex items-center gap-3">
            <span className="flex items-center">
              <img
                src={selectedCountry?.flag}
                alt="flag"
                className="w-6 h-4 mr-1 rounded-[4px] "
              />
              <p className="font-SatoshiRegular text-sm">
                {selectedCountry?.code}
              </p>
            </span>
            <span>
              <IoIosArrowDown className="text-base text-ashShade-2" />
            </span>
          </div>
        </button>
      </div>

      {showDropdown && (
        <div className="absolute top-10 bg-white border border-pbg  w-40 max-h-60 overflow-y-auto z-10 shadow-lg rounded-md">
          <div className="m-2 px-2 flex bg-pbg items-center rounded-md sticky top-0 right-0 left-0">
            <input
              type="text"
              placeholder="Search"
              className="w-full p-2 !border-transparent !border-none outline-none bg-transparent"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <span>
              <IoSearchOutline className="text-xl" />
            </span>
          </div>

          {countriesFiltered.map((country) => (
            <div
              key={country.code}
              className="p-2 flex items-center hover:bg-bbg cursor-pointer font-SatoshiMedium text-sm text-bblack-1"
              onClick={() => handleSelectCountry(country)}
            >
              <img
                src={country.flag}
                alt="flag"
                className="w-6 h-4 mr-2 rounded-[4px]"
              />
              {country.name}
            </div>
          ))}
        </div>
      )}
      <input
        type="text"
        placeholder={selectedCountry?.dialCode}
        className="ml-2 !h-full !w-[70%] !mt-0 border-none !border-l !border-y-0 !border-r-0 !rounded-l-none focus:outline-none !text-sm"
        value={phoneNumber}
        onChange={(e) => {
          setPhoneNumber(e.target.value);
          onPhoneChange({
            country: selectedCountry,
            phoneNumber: e.target.value,
          });
        }}
      />
      {/* <span>{selectedCountry?.dialCode}</span> */}
    </div>
  );
};

export default PhoneInput;

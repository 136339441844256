import { SectionBadge, Title, Subtitle, FeatureItemCard } from "components";
import React from "react";
import { FeaturesIcon } from "assets/icons";
import { benefits } from "../constants";

const Features = () => {
  return (
    <div className="bg-bgSecondary md:px-12">
      <div className="flex flex-col justify-center items-center gap-8 px-4 md:px-0 py-16 max-w-[1234px] mx-auto">
        <SectionBadge title="Benefits" icon={FeaturesIcon} isWhiteColor />
        <div className="flex flex-col justify-center items-center gap-6  text-center max-w-[720px]">
          <Title text="Why this is a great offer" />
          <Subtitle
            text="It gives you the freedom to get what you need today and pay at your own pace—no hidden fees, no stress."
            className="text-center"
          />
        </div>
        <div className="grid md:grid-cols-2 gap-x-6 gap-y-8">
          {benefits.map((feature, index) => (
            <FeatureItemCard
              key={index}
              cardTitle={feature.cardTitle}
              cardBody={feature.cardBody}
              image={feature.image}
              isWhiteColor={feature.isWhiteColor}
              isDeepBlueColor={feature.isDeepBlueColor}
              largeScreenButtonText="Get Started"
              smallScreenButtonText="Get Started"
              linkTo={`/schedule-call`}
              imageStyle={feature.style}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;

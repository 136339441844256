import React, { useState, useEffect } from "react";
import logo from "assets/images/logo.png";
import { AiOutlineMenu } from "react-icons/ai";
import { FiChevronRight, FiArrowLeft } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import { sublinks } from "./constants";
import TopBar from "./TopBar";

interface Props {
  className?: string;
  isBgWhite?: boolean;
  signInLink?: string;
  signUpLink?: string;
  signInFn?: any;
  signUpFn?: any;
  showLogIn?: boolean;
  signUpLabel?: string;
  removeContactUs?: boolean;
  displayInFinance?: boolean;
  displayInFinanceEarn?: boolean;
}

export default function Header({
  signInLink,
  signUpLink,
  className,
  signInFn,
  signUpFn,
  showLogIn,
  signUpLabel,
  removeContactUs,
  displayInFinance,
  displayInFinanceEarn,
}: Props) {
  const navigation = useNavigate();
  const [showMenu, setMenu] = useState(false);
  const [display, setDisplay] = useState("");
  const [displayPricing, setDisplayPricing] = useState("");

  const toggleMenu = () => {
    setMenu((prev) => !prev);
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    showMenu ? disableScroll() : enableScroll();
    display ? disableScroll() : enableScroll();
    displayPricing ? disableScroll() : enableScroll();
  }, [showMenu, display, displayPricing]);

  return (
    <>
      <TopBar
        display={display}
        setDisplay={setDisplay}
        displayPricing={displayPricing}
        setDisplayPricing={setDisplayPricing}
        className={" bg-white z-30"}
        signInLink={signInLink}
        signUpLink={signUpLink}
        showMenu={showMenu}
        toggleMenu={toggleMenu}
        signInFn={signInFn}
        signUpFn={signUpFn}
        showLogIn={showLogIn}
        signUpLabel={signUpLabel}
        removeContactUs={removeContactUs}
        displayInFinance={displayInFinance}
        displayInFinanceEarn={displayInFinanceEarn}
      />

      {showMenu && (
        <div
          onClick={() => setMenu(!showMenu)}
          className={`w-full h-full lg:hidden z-[99] fixed top-0 bg-white text-bblack-1 flex flex-col items-center  left-0 p-auto text-lg`}
        >
          <div
            className={
              "flex py-5 px-4 w-full hover:cursor-pointer self-center  md:px-12 items-center  justify-between z-30 " +
              className
            }
          >
            {" "}
            <div className="2xl:m-auto max-w-[1234px] w-full flex justify-between  items-center">
              <div className="flex justify-between items-center w-full">
                <img
                  src={logo}
                  alt="bnkle"
                  className="md:mr-12"
                  onClick={() => navigation("/")}
                />
                <span className="z-30 lg:hidden">
                  {!showMenu ? (
                    <AiOutlineMenu
                      size={16}
                      className="text-3xl ml-4 lg:hidden z-30"
                      onClick={toggleMenu}
                      color={"headerblue"}
                    />
                  ) : (
                    <MdClose
                      size={16}
                      className="text-xl ml-4 lg:hidden z-30"
                      onClick={toggleMenu}
                      color={"#222B34"}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
          <span
            className=" flex flex-col mt-10 px-4 md:p-2 w-full md:px-12  mb-10  "
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {displayInFinance ? (
              <div className="text-bblack-1 flex justify-between flex-row items-center w-full pb-8 font-SatoshiMedium">
                <Link to="/owner">Private Project Owner</Link>
                <span className="ml-1">
                    <FiChevronRight />
                  </span>
              </div>
            ) : (
              <span className="flex flex-col w-full cursor-pointer  ">
                <span
                  onClick={() => setDisplay("Solutions")}
                  className={`text-bblack-1 flex justify-between items-center w-full pb-8 font-SatoshiMedium`}
                >
                  Solutions
                  <span className="ml-1">
                    <FiChevronRight />
                  </span>
                </span>
                {display === "Solutions" && (
                  <div className="absolute top-0 left-0 w-full h-screen bg-white z-[999] pt-4 px-4 md:px-12">
                    <button onClick={() => setDisplay("")}>
                      <FiArrowLeft />
                    </button>
                    <div className="pt-8 flex flex-col gap-6 ">
                      {sublinks.map((m: any) => (
                        <Link
                          className="text-bblack-1 flex justify-between items-center w-full"
                          to={m.link}
                        >
                          {m.label}
                          <FiChevronRight />
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </span>
            )}

            <div className="flex flex-col gap-8 font-SatoshiMedium pb-8">
              <span className="flex justify-between items-center w-full">
                <a
                  href={`https://calculator.${process.env.REACT_APP_DOMAIN}`}
                  target="_blank"
                >
                  Cost Calculator
                </a>
                <FiChevronRight />
              </span>
              {!displayInFinance && (
                <span className="flex justify-between items-center w-full">
                  <Link to="/pricing" className="text-headerblue">
                    <span className="flex flex-col w-full cursor-pointer  ">
                      <span
                        className={`text-bblack-1 flex justify-between items-center w-full pb-8 font-SatoshiMedium`}
                      >
                        Pricing
                        {/* <span className="ml-1">
                        <FiChevronRight />
                      </span> */}
                      </span>
                    </span>
                  </Link>
                  <FiChevronRight />
                </span>
              )}

              <span className="flex justify-between items-center w-full">
                <Link to="/referral" className="text-headerblue">
                  Refer-to-earn
                </Link>
                <FiChevronRight />
              </span>
              <span className="flex justify-between items-center w-full">
                {!removeContactUs && (
                  <>
                    <Link to="/contact">Contact us</Link>
                    <FiChevronRight />
                  </>
                )}
              </span>
            </div>
            {showLogIn === undefined || showLogIn === true ? (
              <a
                onClick={() => {
                  if (signInFn) {
                    signInFn();
                  }
                }}
                href={signInLink}
                className={` py-2 rounded-md my-3 px-8 w-full border-ashShade-12 border text-center text-bblack-1 font-SatoshiMedium hover:bg-ashShade-13 active:bg-ashShade-14"`}
                rel="no-referer"
              >
                Login
              </a>
            ) : null}
            <a
              onClick={() => {
                if (signUpFn) {
                  signUpFn();
                }
              }}
              href={signUpLink}
            >
              <button className="py-2 rounded-md bg-bblue px-8 w-full text-center text-white">
                {signUpLabel ? signUpLabel : "Sign up"}
              </button>
            </a>
          </span>
        </div>
      )}
      {display && (
        <>
          <div
            onClick={() => {
              setDisplay("");
            }}
            className=" w-screen hidden   bg-black  h-full   bg-opacity-70 lg:flex lg:flex-col jusitify-center z-[90] fixed top-0  left-0 font-SatoshiRegular"
          >
            <div className="top-0 ">
              {
                <div className="w-full">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="w-full z-[999] bg-white  "
                  >
                    <TopBar
                      display={display}
                      setDisplay={setDisplay}
                      displayPricing={displayPricing}
                      setDisplayPricing={setDisplayPricing}
                      className={" bg-white z-30"}
                      signInLink={signInLink}
                      signUpLink={signUpLink}
                      showMenu={showMenu}
                      toggleMenu={toggleMenu}
                      signInFn={signInFn}
                      signUpFn={signUpFn}
                      showLogIn={showLogIn}
                      signUpLabel={signUpLabel}
                      removeContactUs={removeContactUs}
                      displayInFinance={displayInFinance}
                      displayInFinanceEarn={displayInFinanceEarn}
                    />
                  </div>
                </div>
              }
              <div className="w-full  ">
                <div className="">
                  <div className="bg-white rounded-b-lg h-fit w-fit cursor-pointer  p-6 ml-40  ">
                    <p className="text-bblack-1 font-SatoshiMedium mb-8">
                      Solutions
                    </p>
                    <div className={`grid grid-cols-3 gap-x-16  gap-y-6`}>
                      {sublinks.map((m: any) => (
                        <Link to={m.link}>
                          <span className="flex hover:bg-lightblue rounded-md flex-col p-2 ">
                            <span className="flex items-start  gap-2 text-bblack-1 font-SatoshiMedium ">
                              <span
                                className={`rounded-md  p-2 font-Bold ${
                                  m.iconbg ? m.iconbg : " bg-gray-50"
                                } mr-2`}
                              >
                                {React.createElement(m.icon, {
                                  size: 16,
                                  color: m.iconcolor,
                                })}
                              </span>
                              <span>
                                <p>{m.label}</p>
                                <p className="text-bash max-w-[284px]">
                                  {m.description}
                                </p>
                              </span>
                            </span>
                          </span>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useSelector } from "react-redux";
import Loader, { LoaderX } from "../Loader";
import { useNavigate } from "react-router-dom";

const Calendly = () => {
  const navigate = useNavigate();
  const { firstName, lastName, email, phoneNumber } = useSelector(
    (m: any) => m.schedule
  );
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  useCalendlyEventListener({
    onEventScheduled: () => {
      setTimeout(() => {
        navigate("/waitlist-welcome");
      }, 4000);
    },
  });

  return (
    <div className="">
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <InlineWidget
          url="https://calendly.com/buildwithbnkle/projectbrief"
          prefill={{
            name: `${firstName} ${lastName}`,
            email: email,
          }}
        />
      )}
    </div>
  );
};

export default Calendly;

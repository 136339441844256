
import Finance1 from "assets/finance3.png";
import Finance2 from "assets/finance2.png";
import Finance3 from "assets/finance6.png";
import Finance4 from "assets/finance5.png";
import Finance5 from "assets/finance4.png";
import Finance6 from "assets/finance1.png";




export const benefits = [
  {
   
    cardTitle: "Payment Plan",
    cardBody: "Get flexible payments that fit your budget on your own terms.",
    image: Finance1,
    linkTo: "",
  },
  {
    
    cardTitle:
      "Eligibility",
    cardBody:
      "If you live and work in Australia, Canada, UK, USA or Europe, you may qualify to build or renovate your construction projects in Africa up to US$85,000.",
    image: Finance2,
    linkTo: "",
    isWhiteColor: true,
  },
  {
    
    cardTitle: "Project Tracking",
    cardBody:
      "Technology supported real time monitoring, updates and independent verification on your project so you don't have to be a statistics — one of the people who rely on friends and family members who got burned.",
    image: Finance3,
    linkTo: "",
    isWhiteColor: true,
  },
  {
    
    cardTitle:
      "Prompt Project Delivery",
    cardBody:
      "Complete your dream home in 5 months rather than drag it out for 18 months or more. Start and stops make construction projects more expensive due to the re-mobilizing workers at every pause.",
    image: Finance4,
    linkTo: "",
    isDeepBlueColor: true,
  },
  {
   
    cardTitle: "Building Materials Cost Control",
    cardBody: "You’re protected from rising building material costs, which often increase over time, preventing abandoned projects.",
    image: Finance5,
    linkTo: "",
  },
  {
   
    cardTitle:
      "Professional Guarantee",
    cardBody:
      "Get access to top quality professionals — Builders, consultants, who also provide insurance bonds or advanced payment guarantees.",
    image: Finance6,
    linkTo: "",
    isWhiteColor: true,
    style:'h-[300px] w-[300px] lg:w-[400px] flex self-center lg:self-end justify-center px-8 pb-20'
  },

]

export const faqData=[
  {
    question: "Can I buy instead of build?",
    answer: "Currently, we only support our users who are building.",
  },
  {
    question: "Are land purchases included?",
    answer:
      "No, this does not include land purchases",
  },
 
  {
    question: "Does it include consulting services?",
    answer:
      "Yes, it includes services to cover Architects, Mechanical, Electrical and Structural engineers",
  },

  
]
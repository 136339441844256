import React from "react";
import HeroImage from "../../../../src/assets/images/portrait-cardboard-black-family.png";
import HeroImageMobile from "assets/images/Frame 1618872823.png";
import Button from "components/shared/Button";
import "../index.css"
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full lg:h-full pt-8 sm:pt-20">
      <div className="relative h-full w-full flex items-center flex-col justify-center 2xl:max-w-[1234px] 2xl:mx-auto">
        <div className="text-center flex items-center justify-center flex-col gap-4 w-full px-3 sm:px-0 z-50">
          <h1 className="font-SatoshiBold text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-bblack-1">
            Build Now, Pay Later.
          </h1>
          <h3 className="font-semibold font-satoshi text-base sm:text-2xl md:text-3xl text-bblack-1 ">
            $0 payment until completion.
          </h3>
          <p className="sm:font-SatoshiMedium font-SatoshiRegular text-bash text-sm md:text-base sm:w-[53ch] md:w-[60ch]">
            Build in Africa with flexible payment plans that fit your budget.
            Whether you want to build your dream home, execute a real estate
            investment or renovate your parents' home, Bnkle gives you the tools
            and resources to build from anywhere.
          </p>

          <Button
            style={{
              backgroundColor: "#437ADB",
              color: "#fff",
            }}
            className="md:w-auto w-full"
            label={"Get Started"}
            onClick={() => {
              navigate("/schedule-call");
            }}
          />
        </div>

        <div className="gradient-div sm:block hidden"></div>
        <div className="">
          <img src={HeroImage} className="hidden sm:block" />
          <img src={HeroImageMobile} className=" sm:hidden block" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

import { TbMathSymbols } from "react-icons/tb";
import { FiUser, FiUsers } from "react-icons/fi";
import { RiBuilding4Line } from "react-icons/ri";

export const sublinks = [
  {
    label: "Private Project Owners",
    link: "/owner",
    icon: FiUser,
    iconbg: "bg-[#ECF2FB]",
    iconcolor: "#437ADB",
    description:
      "Get end-to-end project procurement that optimizes speed and quality at the right price.",
  },
  {
    label: "Enterprise Project Owners",
    link: "/enterprise",
    icon: RiBuilding4Line,
    iconbg: "bg-[#FFF3EB]",
    iconcolor: "#FF8A34",
    description:
      "Access the tools to deliver satisfaction to every client, every time.",
  },
  {
    label: "Build Now Pay Later",
    link: "/finance",
    icon: RiBuilding4Line,
    iconbg: "bg-[#F6E8ED]",
    iconcolor: "#A7194B",
    description:
      "Access the tools to deliver satisfaction to every client, every time.",
  },

  {
    label: "Contractor",
    link: "/contractor",
    icon: FiUsers,
    iconbg: "bg-[#E9F6F2]",
    iconcolor: "#26A87D",
    description:
      "Gain access to bid on an endless pool of new projects at the speed of a click",
  },
  {
    label: "Consultant",
    link: "/consultant",
    icon: FiUsers,
    iconbg: "bg-[#E9F6F2]",
    iconcolor: "#26A87D",
    description:
      "Gain access to bid on an endless pool of new projects at the speed of a click",
  },

  // {
  //     label: "Doctors",
  //     link: "/doctor",
  //     icon: FiUsers,
  //     iconbg: "bg-[#E9F6F2]",
  //     iconcolor: "#26A87D",
  //     description:
  //         "Access the tools to deliver satisfaction to every client, every time.",
  // },
];
// export const pricingSublinks = [
// 	{
// 		label: "Normal Price",
// 		link: "/pricing/normal",
// 		icon: FiUser,
// 		iconbg: "bg-[#ECF2FB]",
// 		iconcolor: "#437ADB",
// 		description:
// 			"Get end-to-end project procurement that optimizes speed and quality at the right price.",
// 	},
// 	{
// 		label: "Premium Price",
// 		link: "/pricing/premium",
// 		icon: RiBuilding4Line,
// 		iconbg: "bg-[#FFF3EB]",
// 		iconcolor: "#FF8A34",
// 		description:
// 			"Access the tools to deliver satisfaction to every client, every time.",
// 	},

// ];

import { useEffect, useRef } from "react";
import logo from "assets/images/logo.png";
import { useNavigate, Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { MdClose } from "react-icons/md";

interface Prop {
  className?: string;
  signInLink?: string;
  signUpLink?: string;
  showMenu: boolean;
  toggleMenu: () => void;
  display: string;
  displayPricing: string;
  setDisplay: any;
  setDisplayPricing: any;
  signUpFn?: any;
  signInFn?: any;
  showLogIn?: boolean;
  signUpLabel?: string;
  removeContactUs?: boolean;
  displayInFinance?: boolean;
  displayInFinanceEarn?: boolean;
}

const TopBar = ({
  className,
  signInLink,
  signUpLink,
  toggleMenu,
  showMenu,
  display,
  setDisplay,
  displayPricing,
  setDisplayPricing,
  signInFn,
  signUpFn,
  showLogIn,
  signUpLabel,
  removeContactUs,
  displayInFinance,
  displayInFinanceEarn,
}: Prop) => {
  let navigation = useNavigate();
  const pricingDropdownRef = useRef<HTMLDivElement>(null);

  const showDisplay = () => {
    if (display === "Solutions") {
      setDisplay("");
    } else {
      setDisplay("Solutions");
    }
  };

  const showDisplayPricing = () => {
    if (displayPricing === "Pricing") {
      setDisplayPricing("");
    } else {
      setDisplayPricing("Pricing");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pricingDropdownRef.current &&
        !pricingDropdownRef.current.contains(event.target as Node)
      ) {
        setDisplayPricing("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDisplayPricing]);

  return (
    <nav className="sticky top-0 z-50 w-full px-5 md:px-16 bg-white text-headerblue font-inter font-medium cursor-pointer">
      <div className="flex justify-between items-center referral-container w-full h-20">
        <div className="flex items-center gap-9">
          <div>
            <img src={logo} alt="bnkle logo" onClick={() => navigation("/")} />
          </div>
          <div className="hidden lg:flex items-center gap-6">
            {displayInFinance ? (
              <div>
                <Link to="/owner">Private Project Owner</Link>
              </div>
            ) : (
              <div
                onClick={showDisplay}
                className="flex items-center gap-[2px]"
              >
                <span>Solutions</span>
                <span>{display ? <FiChevronUp /> : <FiChevronDown />}</span>
              </div>
            )}

            <div>
              <a
                href={`https://calculator.${process.env.REACT_APP_DOMAIN}`}
                target="_blank"
                rel="noreferrer"
              >
                Cost Calculator
              </a>
            </div>
            <Link to="/referral">{displayInFinanceEarn ? "Refer & Earn $300" : "Refer-to-earn"} </Link>
            {!displayInFinance && (
              <div className="relative" ref={pricingDropdownRef}>
                <div
                  className="flex items-center gap-[2px]"
                  onClick={showDisplayPricing}
                >
                  <span>Pricing</span>
                  <span>
                    {displayPricing ? <FiChevronUp /> : <FiChevronDown />}
                  </span>
                </div>
                {displayPricing && (
                  <div className="absolute bg-white shadow-lg mt-2 p-4 w-40 z-10 rounded-lg">
                    <Link
                      to="/pricing/enterprise"
                      className="block p-2 hover:bg-[#437ADB] hover:text-[#ffffff] text-[12px] text-[#77828D]"
                      onClick={() => setDisplayPricing("")}
                    >
                      Enterprise Plan
                    </Link>
                    <Link
                      to="/pricing/premium"
                      className="block p-2 hover:bg-[#437ADB] text-[12px] hover:text-[#ffffff] text-[#77828D]"
                      onClick={() => setDisplayPricing("")}
                    >
                      Premium Plan
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="hidden lg:flex items-center gap-6">
          {!removeContactUs && <Link to="/contact">Contact us</Link>}
          {showLogIn === undefined || showLogIn === true ? (
            <a
              onClick={() => {
                if (signInFn) {
                  signInFn();
                }
              }}
              href={signInLink}
              className="text-headerblue border border-ashShade-12 py-[7px] px-4 rounded-lg hover:bg-ashShade-13 active:bg-ashShade-14"
              rel="no-referrer"
            >
              Login
            </a>
          ) : null}
          <a
            onClick={() => {
              if (signUpFn) {
                signUpFn();
              }
            }}
            href={signUpLink}
          >
            <button className="py-2 rounded-md bg-bblue px-6 text-white hover:bg-blueShades-1 active:bg-blueShades-0">
              {signUpLabel ? signUpLabel : "Sign up"}
            </button>
          </a>
        </div>
        <span className="lg:hidden">
          {showMenu ? (
            <MdClose onClick={toggleMenu} />
          ) : (
            <AiOutlineMenu onClick={toggleMenu} />
          )}
        </span>
      </div>
    </nav>
  );
};

export default TopBar;

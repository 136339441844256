import React from 'react'

interface InputFieldProps {
name: string;
labelText: string;
className?: string;
placeholder?: string;
onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
value: string;
error?:string;
}

const index = ({name, labelText, className, placeholder, onChange, value, error }: InputFieldProps) => {
  return (
    <label className={`font-SatoshiMedium ${className}`} htmlFor={name}>
      {labelText}
    <input name={name} className="" placeholder={placeholder} onChange={onChange} value={value} />
    {error && <span className="text-ERROR text-[15px]">{error}</span>}
    
  </label>
  )
}

export default index
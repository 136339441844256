import Footer from 'components/newShared/Footer'
import Header from 'components/newShared/Header'
import React from 'react'
import ScheduleForm from './components/ScheduleForm'

const ScheduleCall = () => {
  return (
    <div>
       <div>
        <div>
          <Header removeContactUs={true} signUpLabel="Learn more" displayInFinance={true} signUpLink={"/schedule-call"} displayInFinanceEarn={true} />
        </div>

        <div className="w-full">
          <ScheduleForm/>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default ScheduleCall
import Header from "components/newShared/Header";
import React from "react";
import HeroSection from "./components/HeroSection";
import Features from "./components/Features";
import FaqSection from "./components/FaqSection";
import { TestimonialSection } from "components";
import Footer from "components/newShared/Footer";

const Finance = () => {
  return (
    <div>
      <div>
        <div className="lg:border-b lg:border-ashShade-3">
          <Header
            removeContactUs={true}
            signUpLabel="Get Started"
            displayInFinance={true}
            signUpLink={"/schedule-call"}
            displayInFinanceEarn={true}
          />
        </div>

        <div className="w-full">
          <HeroSection />
          <Features />
          <TestimonialSection />
          <FaqSection />
          <div className="my-10" />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Finance;

import { createSlice } from "@reduxjs/toolkit";
import { scheduleCall } from "pages/scheduleCall/helper/constants";



const initialState: scheduleCall = {
firstName: "",
lastName: "",
email: "",
phoneNumber: 0,
}

const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    setSchedule: (state, action) => {
      const {firstName, lastName, email, phoneNumber} = action.payload;
      state.firstName = firstName;
      state.lastName = lastName;
      state.email = email;
      state.phoneNumber = phoneNumber;
    },
  },
})

export const {setSchedule} = scheduleSlice.actions;
export default scheduleSlice.reducer;
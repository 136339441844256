import Footer from "components/newShared/Footer";
import Header from "components/newShared/Header";
import Calendly from "components/shared/Calendly/Calendly";
import React from "react";

const CalendlyPage = () => {
  return (
    <div>
      <Header
        signUpLabel="Sign up"
        signInLink={`https://auth.${process.env.REACT_APP_DOMAIN}/signin`}
        signUpLink={`https://auth.${process.env.REACT_APP_DOMAIN}`}
        displayInFinanceEarn={true} 
      />
      <div className="bg-bgSecondary h-fit">
        <Calendly />
        <Footer />
      </div>
    </div>
  );
};

export default CalendlyPage;

import React from "react";
import Header from "components/newShared/Header";
import Footer from "components/newShared/Footer";
import { TbHeadset } from "react-icons/tb";
import { MdMailOutline } from "react-icons/md";
import { IoGiftOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/shared/Button";

const ThankYouPage = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen">
      <Header
        signUpLabel="Sign up"
        signInLink={`https://auth.${process.env.REACT_APP_DOMAIN}/signin`}
        signUpLink={`https://auth.${process.env.REACT_APP_DOMAIN}`}
        displayInFinanceEarn={true}
        displayInFinance={true}
        removeContactUs={true}
      />
      <div className="bg-bgSecondary flex items-center justify-center py-14">
        <div className="text-center flex items-center justify-center flex-col gap-5">
          <p className="font-SatoshiMedium text-base">You’re in!🎉</p>
          <h1 className="font-SatoshiBold text-3xl ">
            Thanks for joining the Build Now,<br className="hidden sm:block"></br> Pay Later waitlist!
          </h1>
          <p className="font-SatoshiMedium text-base">What’s Next?</p>

          <div className="flex flex-wrap gap-4 my-5 items-center justify-center">
            <div className="border-[1.2px] border-ashShade-3 p-3 rounded-md w-[90%] sm:w-[292px] h-[160px] text-left">
              <div className="h-full flex flex-col justify-between">
                <p className="text-bblack-1 font-SatoshiMedium text-base">
                  You’ll be among the first to access our service.
                </p>

                <TbHeadset className="text-bash text-2xl " />
              </div>
            </div>
            <div className="border-[1.2px] border-ashShade-3 p-4 rounded-md w-[90%] sm:w-[292px] h-[160px] text-left">
              <div className="h-full flex flex-col justify-between">
                <p className="text-bblack-1 font-SatoshiMedium text-base">
                  Keep an eye on your inbox for updates.
                </p>

                <MdMailOutline className="text-bash text-2xl " />
              </div>
            </div>
            <div className="border-[1.2px] border-ashShade-3 p-3 rounded-md w-[90%] sm:w-[292px] h-[160px] text-left">
              <div className="h-full flex flex-col justify-between">
                <p className="text-bblack-1 font-SatoshiMedium text-base">
                  Want to earn $300 for each referral?{" "}
                  <Link className="text-bblue underline" to="/referral">
                    Share with a friend!
                  </Link>{" "}
                </p>

                <IoGiftOutline className="text-bash text-2xl " />
              </div>
            </div>
          </div>

          <div className="flex flex-col w-[90%] sm:w-fit sm:flex-row gap-5">
            <Button className="bg-bblue" label="Learn more about Bnkle?" onClick={() => navigate("/owner")}/>
            <Button className="bg-bgSecondary border border-ashShade-4 !text-bblack-1 " label="Got questions? Contact us" onClick={() => navigate("/contact")}/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ThankYouPage;

import React, { useRef, useState } from "react";
import "../index.css";
import Button from "components/shared/Button";
import CountryDropdown from "components/shared/SelectCountry/CountryDropdown";
import CountryPhoneDropdown from "components/shared/CountryPhone/CountryPhoneDropdown";
import { Formik, Form, FormikProps, Field } from "formik";
import { scheduleCallSchema } from "../helper/ScheduleCallSchema";
import InputField from "components/shared/InputField";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSchedule } from "../../../redux/scheduleSlice";
import { toast } from "react-toast";

interface Country {
  name: string;
  code: string;
  flag: string;
}

interface PhoneData {
  country: Country | null;
  phoneNumber: string;
}

const defaultCountry: Country = {
  name: "Canada",
  code: "CA",
  flag: "https://flagcdn.com/ca.svg",
};

const displayMessage = (message = "") => {
  toast(message, {
    backgroundColor: "black",
    color: "#fff",
  });
};

const ScheduleForm = () => {
  const formikRef = useRef<FormikProps<{
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: number;
    targetCountry: string;
    comment: string;
    residentCountry: string;
    purpose: string;
  }> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleScheduleCall = async () => {
    setLoading(true);
    if (formikRef.current) {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        targetCountry,
        comment,
        residentCountry,
        purpose,
      } = formikRef.current.values;
      try {
        await formikRef.current.submitForm();
        if (
          !formikRef.current?.errors ||
          Object.keys(formikRef.current.errors).length === 0
        ) {
          const removePlus = phoneNumber.toString().replace("+", "");
          const payload = {
            firstName,
            lastName,
            email,
            phoneNumber: removePlus,
            residentCountry,
            buildCountry: targetCountry,
            purpose,
            ...(comment && { comment: comment }),
          };
          const response = await axios.post(
            `${process.env.REACT_APP_IAM_URL}/waitlist/add`,
            payload
          );
          if (response.status === 201) {
            dispatch(
              setSchedule({
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: phoneNumber,
              })
            );
            displayMessage("Success");
            navigate("/schedule-call-calendly");
          } else {
            displayMessage("Error submitting form: " + response?.data?.message);
          }
        } else {
        }
      } catch (e: any) {
        displayMessage(
          "Error submitting form: " + e?.response?.data?.error || ""
        );
      } finally {
        setLoading(false);
      }
    } else {
      console.error("Formik ref not found-Adding group");
    }
  };

  return (
    <div className="bg-bgSecondary flex justify-center items-center ">
      <form
        className="bg-white w-[90%] sm:w-[560px] rounded-md p-8 my-10"
        onClick={(e) => e.preventDefault()}
      >
        <div className="text-bblack-1 sm:text-center text-left flex flex-col justify-center items-center gap-1">
          <h1 className="font-SatoshiBold text-3xl">
            Take the First Steps Towards your Dream Home!
          </h1>
          <p className="text-base font-SatoshiRegular">
            Fill out the form below to get you started and schedule a call
            towards your project
          </p>
        </div>

        <Formik
          innerRef={(el) => {
            if (el) formikRef.current = el;
          }}
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: 0,
            targetCountry: "",
            comment: "",
            residentCountry: "",
            purpose: "",
          }}
          onSubmit={() => {}}
          validationSchema={scheduleCallSchema}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form
              className=""
              placeholder=""
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            >
              <div className=" mt-6 flex flex-col gap-4 form-div font-SatoshiMedium">
                <div className="flex sm:flex-row flex-col gap-3 justify-between">
                  <InputField
                    name="firstName"
                    labelText="First name"
                    className="sm:!w-[63%] w-full"
                    placeholder="Shade"
                    onChange={handleChange}
                    value={values.firstName}
                    error={(touched.firstName && errors.firstName) || ""}
                  />

                  <InputField
                    name="lastName"
                    labelText="Last name"
                    className="sm:!w-[63%] w-full"
                    placeholder="Chukwu"
                    onChange={handleChange}
                    value={values.lastName}
                    error={(touched.lastName && errors.lastName) || ""}
                  />
                </div>
                <InputField
                  name="email"
                  labelText="Email"
                  placeholder="e.g forexample@gmail.com"
                  onChange={handleChange}
                  value={values.email}
                  error={(touched.email && errors.email) || ""}
                />

                <label>
                  Phone number
                  <CountryPhoneDropdown
                    onPhoneChange={({ country, phoneNumber }) => {
                      setFieldValue("phoneNumber", phoneNumber);
                      setFieldValue(
                        "residentCountry",
                        country?.name || defaultCountry.name
                      );
                    }}
                  />
                  {touched.phoneNumber && errors.phoneNumber && (
                    <span className="text-ERROR text-[15px]">
                      {errors.phoneNumber}
                    </span>
                  )}
                  {touched.residentCountry && errors.residentCountry && (
                    <span className="text-ERROR text-[15px]">
                      {errors.residentCountry}
                    </span>
                  )}
                </label>
                <label>
                  Where do you want to build?
                  <CountryDropdown
                    onSelect={(country) =>
                      setFieldValue("targetCountry", country.name || "")
                    }
                  />
                  {touched.targetCountry && errors.targetCountry && (
                    <span className="text-ERROR text-[15px]">
                      {errors.targetCountry}
                    </span>
                  )}
                </label>
                <label className="flex flex-col text-bash w-full justify-between font-SatoshiMedium text-sm">
                  Select type of project
                  <div className="flex w-full sm:!flex-row !flex-col justify-between mt-1 gap-3 text-base font-SatoshiRegular project-div">
                    <label
                      className="!w-full sm:!w-[63%]"
                      onClick={() => setFieldValue("purpose", "New Build")}
                    >
                      New Build
                      <Field type="radio" name="purpose" value="New Build" />
                    </label>

                    <label
                      className="!w-full sm:!w-[63%]"
                      onClick={() => setFieldValue("purpose", "Renovation")}
                    >
                      Renovation
                      <Field type="radio" name="purpose" value="Renovation" />
                    </label>
                  </div>
                  {touched.purpose && errors.purpose && (
                    <span className="text-ERROR text-[15px]">
                      {errors.purpose}
                    </span>
                  )}
                </label>
                <label className="font-SatoshiMedium" htmlFor="comment">
                  Comment (optional)
                  <textarea
                    name="comment"
                    placeholder="Leave a comment"
                    className=""
                    onChange={handleChange}
                    value={values.comment}
                  />
                </label>
              </div>
            </Form>
          )}
        </Formik>

        <Button
          label={"Submit & Schedule a Call"}
          style={{
            backgroundColor: "#437ADB",
          }}
          onClick={handleScheduleCall}
          className="!text-white w-full mt-6"
          isLoading={loading}
        />
      </form>
    </div>
  );
};

export default ScheduleForm;
